import {FC, Fragment} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import {App} from '../App'
import {AuthPage, useAuth} from "../modules/auth";

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
    const {currentUser} = useAuth()
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>
                    {currentUser ? (
                        <Fragment>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Route path='auth/*' element={<AuthPage />} />
                            <Route path='*' element={<Navigate to='/auth' />} />
                        </Fragment>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
