import { createRoot } from 'react-dom/client';

import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'

import {MetronicI18nProvider} from '_metronic/i18n/Metronici18n'

import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'

import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from 'app/modules/auth'

setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')

if (container) {
    createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
                <AuthProvider>
                    <AppRoutes />
                </AuthProvider>
            </MetronicI18nProvider>
        </QueryClientProvider>
    )
}
